/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.return-up-route {
  height: 16px;
  left: 20px;
  top: 0px;
  font-family: PingFang SC;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 16px;
  color: #999999;
  flex: none;
  order: 1;
  align-self: center;
  margin: 4px 0px 36px;
}
.return-up-route .margin-r6 {
  margin-right: 6px;
}
.return-up-route .color-blue {
  color: #2d63ff;
}
.return-up-route .margin-l8 {
  margin-left: 8px;
}
.return-up-route .back {
  cursor: pointer;
}
